<template>
  <div class="meeting-room-join">
    <MeetingVideoRoom />
  </div>
</template>

<script>
import { mapActions } from "pinia";

import MeetingVideoRoom from "@/components/MeetingRoom/MeetingVideoRoom";
import { useMeetingRoomStore } from "@/pinia-store/meetingRoom";

export default {
  name: "MeetingRoom",
  components: { MeetingVideoRoom },
  watch: {
    async $route() {
      await this.resetRoom();
    },
  },
  methods: {
    ...mapActions(useMeetingRoomStore, ["resetRoom"]),
  },
  destroyed() {
    this.resetRoom();
  },
};
</script>

<style lang="scss" scoped>
.meeting-room-join {
}
</style>
